<template>
  <section class="after_sales">
    <el-dialog :visible="visible" width="18%" :show-close="false">
      <h3>微信扫码，进入<span>“青葫芦会员商城”</span>小程序，申请售后。</h3>
      <div class="sun_code">
        <img src="@/assets/images/after-sales.png">
      </div>
      <div class="operation" @click="cancelClick">我知道了</div>
    </el-dialog>
  </section>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      cancelClick() {
        this.$emit('update:visible', false);
      }
    }
  }
</script>

<style lang="less" scoped>
  .after_sales {
    padding: 50px;
    h3 {
      font-size: 16px;
      color: #000;
      font-weight: normal;
      text-align: center;
      span {
        font-weight: bold;
      }
    }
    .sun_code {
      margin: 30px auto;
      width: 200px;
      height: 200px;
    }
    .operation {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      background: #ED6D00;
      font-size: 16px;
      color: #FFF;
      font-weight: bold;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
</style>
