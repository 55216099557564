<template>
  <section class="order_detail">
    <NavBar />
    <Header type="personalCenter" />
    <div class="content box pt30">
      <div class="page_location">当前位置：订单列表-订单详情</div>
      <div class="order_info mt30">
        <div class="title">订单信息</div>
        <div class="info mt30 p30 fcsbfs">
          <div>
            订单编号：{{orderDetail.orderNo}}
          </div>
          <div>
            订单时间：{{orderDetail.createTime}}
          </div>
          <div class="order_amount">
            订单金额：<span>{{orderDetail.totalMoney}}元</span>
          </div>
          <div class="order_status frfsc">
            订单状态：{{orderDetail.status | orderStatus}}
            <div class="stay_pay ml15 frfsc" v-if="orderDetail.status == 0">
              <div class="go_pay" @click="goPay">去支付</div>
              <div class="countdown ml5">{{payCountdown}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="goods_info mt30">
        <div class="title">收货信息</div>
        <div class="info mt30 p30 frfsc">
          {{orderDetail.receiverProvince}} {{orderDetail.receiverCity}} {{orderDetail.receiverArea}} {{orderDetail.receiverAddress}} {{orderDetail.linkMan}} {{orderDetail.linkPhone}}
          <div class="ml15" @click="seeLogistics" v-if="orderDetail.status == 2 || orderDetail.status == 9">查看物流</div>
        </div>
      </div>
      <div class="procurement_listing mt30">
        <div class="header frsbc">
          <div class="left frfsc">
            <div class="title">采购清单</div>
            <div class="export_listing ml15" @click="exportListing">导出清单</div>
          </div>
          <div class="right">
            <div class="after_sales mr15" @click="afterSales" v-if="orderDetail.canceled != 1 && (orderDetail.status == 2 || orderDetail.status == 9)">申请售后</div>
            <div class="buy" @click="buy" v-if="orderDetail.status !== 0">再次购买</div>
          </div>
        </div>
        <el-table class="mt30" :data="tableData" stripe border>
          <!-- <el-table-column type="index" align="center"></el-table-column> -->
          <el-table-column label="商品ISBN" prop="isbn" align="center"></el-table-column>
          <el-table-column label="商品名称" prop="goodsName" align="center"></el-table-column>
          <el-table-column label="单价（元）" prop="salePrice" align="center">
            <template slot-scope="scope">
              <span>{{(+scope.row.salePrice).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="采购数量" prop="goodsNum" align="center"></el-table-column>
          <el-table-column label="已发数量" prop="issuedNum" align="center"></el-table-column>
          <el-table-column label="小计（元）" prop="payPrice" align="center"></el-table-column>
        </el-table>
        <Pagination :pageIndex.sync="paging.pageIndex" :pageSize.sync="paging.pageSize" :total="paging.total" @change="getShoppingList" />
        <PayAmount :payAmount="payAmount" />
      </div>
    </div>
    <AfterSales :visible.sync="afterSalesVisible" />
  </section>
</template>

<script>
  import {Mixin} from 'utils/mixins.js';
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import AfterSales from './componnets/AfterSales.vue';
  import Pagination from 'components/Pagination/index.vue';
  import PayAmount from '../../../settlementPage/components/PayAmount.vue';
  import {
    getOrderDetail,
    getShoppingList,
    exportListing,
    buy
  } from 'api/personalCenter.js';

  export default {
    mixins: [Mixin],

    components: {
      NavBar,
      Header,
      AfterSales,
      Pagination,
      PayAmount
    },

    created() {
      if(this.$route.params.orderNo) {
        this.orderNo = this.$route.params.orderNo;
        this.getOrderDetail()
        this.getShoppingList();
      }else {
        this.$router.push({
          name: 'personalCenter'
        });
      }
    },

    data() {
      return {
        orderNo: '',
        orderDetail: {},
        tableData: [],
        paging: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        },
        payAmount: {},
        afterSalesVisible: false
      }
    },

    computed: {
      payCountdown() {
        let hours = Math.floor(this.orderDetail.MSNumber / 3600000);
        let minutes = Math.floor(this.orderDetail.MSNumber % 3600000 / 60000);
        let seconds = Math.floor(this.orderDetail.MSNumber % 3600000 % 60000 / 1000);
        return `${hours}时${minutes}分${seconds}秒`;
      }
    },

    methods: {
      getOrderDetail() {
        getOrderDetail({
          orderNo: this.orderNo
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.orderDetail = res.data;
          if(this.orderDetail.status == 0) {
            let timeDifference = new Date(this.orderDetail.implementCloseTime).getTime() - new Date().getTime();
            this.$set(this.orderDetail, 'MSNumber', timeDifference);
            setInterval(() => {
              this.orderDetail.MSNumber -= 1000;
            }, 1000);
          }
          this.payAmount = {
            price: res.data.orderMoney,
            activityDisMoney: res.data.disMoney,
            fare: res.data.sendMoney,
            count: res.data.orderMoney + res.data.sendMoney - res.data.disMoney
          };
        });
      },
      goPay() {
        this.$router.replace({
          name: 'payPage',
          params: {
            orderNo: this.orderNo
          }
        });
      },
      getShoppingList() {
        getShoppingList({
          orderNo: this.orderNo,
          pageIndex: this.paging.pageIndex,
          pageSize: this.paging.pageSize
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.tableData = res.data.records;
          this.paging.total = res.data.total;
        });
      },
      exportListing() {
        exportListing({
          orderNo: this.orderNo
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success('操作成功！');
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(new Blob([res]));
          a.setAttribute('download', '采购清单' + '.xlsx');
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
        });
      },
      afterSales() {
        this.afterSalesVisible = true;
      },
      buy() {
        buy({
          orderNo: this.orderNo
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$router.replace({
            name: 'settlementPage'
          });
        });
      },
      seeLogistics() {
        this.$router.push({
          name: 'seeLogistics',
          params: {
            orderNo: this.orderDetail.orderNo,
            orderType: this.orderDetail.orderType
          }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .order_detail {
    .content {
      padding: 30px 0 100px;
      .page_location {
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: 400;
      }
      .title {
        font-size: var(--fontSize20);
        color: var(--color000);
        font-weight: bold;
        line-height: 30px;
      }
      .order_info {
        .info {
          width: 100%;
          height: 200px;
          background: var(--colorfbfbfb);
          border: 1px solid var(--colorccc);
          div {
            font-size: var(--fontSize14);
            color: var(--color000);
            font-weight: 500;
          }
          .order_amount {
            span {
              color: var(--colorfe1818);
            }
          }
          .order_status {
            .stay_pay {
              .go_pay {
                width: 60px;
                height: 30px;
                background: var(--colored6d00);
                border-radius: 8px;
                font-size: var(--fontSize12);
                color: var(--colorfff);
                font-weight: bold;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
              }
              .countdown {
                font-size: var(--fontSize12);
                color: var(--colorfe1818);
                font-weight: 500;
              }
            }
          }
        }
      }
      .goods_info {
        .info {
          width: 100%;
          background: var(--colorfbfbfb);
          border: 1px solid var(--colorccc);
          font-size: var(--fontSize14);
          color: var(--color000);
          font-weight: 500;
          div {
            font-size: var(--fontSize14);
            color: var(--color1890ff);
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
      .procurement_listing {
        .header {
          .left {
            .export_listing {
              width: 90px;
              height: 30px;
              background: var(--colorfbfbfb);
              border: 1px solid var(--colord9d9d9);
              border-radius: 4px;
              font-size: var(--fontSize14);
              color: var(--color333);
              font-weight: 500;
              text-align: center;
              line-height: 28px;
              cursor: pointer;
            }
          }
          .right {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .after_sales {
              width: 90px;
              height: 30px;
              border: 1px solid var(--colored6d00);
              border-radius: 8px;
              font-size: var(--fontSize14);
              color: var(--colored6d00);
              font-weight: bold;
              text-align: center;
              line-height: 28px;
              cursor: pointer;
            }
            .buy {
              width: 90px;
              height: 30px;
              border-radius: 8px;
              background: var(--colored6d00);
              font-size: var(--fontSize14);
              color: var(--colorfff);
              font-weight: bold;
              text-align: center;
              line-height: 28px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>
